<template>
  <div>
    <lock-screen-loader v-if="enableLoader"/>
    <app-alert
      v-if="showAlert"
      :alertData="alertData"
      @dismissed="onAlertDismiss"
    />
    <research-dialog
      :dialogConfig="researchDialog"
      :showDialog="showResearchDialog"
      @dialog-success="handleDialogSuccess"
      @dialog-cancel="handleDialogCancel"
      @dialog-dismissed="handleDialogDismiss"
    ></research-dialog>
    <mds-alert
      size="small"
      persistent= persistent
      variation="informational"
      title="Instructions"
      ><div v-html="tabConfig.instructionsText" />
    </mds-alert>
    <research-form
      :formData="formData"
      :formId="formId"
      @select-file="handleFileSelection"
    ></research-form>

    <mds-button-container right-aligned class="research-form-action-btn">
      <mds-button v-if="isFormSubmitted" variation="secondary" @click="onCancel"> Cancel </mds-button>
      <mds-button variation="primary" :disabled="submitDisabled" @click="onSubmit" >Submit</mds-button>
      <mds-button variation="primary" :disabled="publishDisabled"  @click="onPublish">Publish To Prod</mds-button>
    </mds-button-container>
  </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from "@mds/button";
import cloneDeep from 'lodash/cloneDeep';
import researchFormConfig from '@/data/publishResearch.json';
import LockScreenLoader from '@/components/Common/LockScreenLoader/LockScreenLoader.vue';
import AppAlert from '@/components/Common/AppAlert/AppAlert';
import {
  editResearchData,
  publishResearchData,
  getResearchList,
  revertResearch
} from "@/services/researchService.js";
import moment from "moment";
import { sendMail } from "@/services/emailService.js"
import { mapGetters } from 'vuex';
import MdsAlert from '@mds/alert';
import ResearchForm from './ResearchForm.vue';
import ResearchDialog from './ResearchDialog.vue';

export default {
  name: "EditResearch",
  components: {
    MdsButton,
    MdsButtonContainer,
    LockScreenLoader,
    AppAlert,
    MdsAlert,
    ResearchForm,
    ResearchDialog
  },
  data() {
    return {
      formData: cloneDeep(researchFormConfig?.formConfig),
      formDataCopy: {},
      file: null | File,
      fileName: '',
      enableLoader: false,
      isFileChanged: false,
      showAlert: false,
      alertData: {},
      dialogConfig: cloneDeep(researchFormConfig?.dialogConfig),
      researchDialog: {},
      showResearchDialog: false,
      emailConfig: cloneDeep(researchFormConfig?.emailConfig),
      persistent:true,
      formId: "edit-research",
      isFormSubmitted: false,
      tabConfig: cloneDeep(researchFormConfig?.tabsConfig.tabs[1]),
      publishTarget:'',
      newResearchId: '',
      oldResearchId: '',
    };
  },
  created() {
    this.getRecentResearch();
  },
  computed: {
    ...mapGetters({
      userName: 'user/userName',
    }),
    isFormChanged() {
      return JSON.stringify(this.formData) !== JSON.stringify(this.formDataCopy);
    },
    isFormDirty() {
      return JSON.stringify(this.formData) !== JSON.stringify(this.formDataCopy) || this.isFileChanged || this.isFormSubmitted;
    },
    submitDisabled(){
      return !this.isFormChanged && !this.isFileChanged;
    },
    publishDisabled(){
      return !this.isFormSubmitted || !this.submitDisabled;
    },
  },
  watch: {
    isFormDirty: 'handleTabSwitch',
  },
  methods: {
    getRecentResearch() {
      this.enableLoader = true;
      getResearchList()
        .then((res) => {
          if (res) {
            this.enableLoader = false;
            const keyPoints = res[0].keyPoints.length > 1 ? res[0].keyPoints.join("\n") : res[0].keyPoints[0];
            this.formData.id = res[0].id;
            this.formData.title.value = res[0].title;
            this.formData.description.value = res[0].description;
            this.formData.status = res[0].status;
            this.formData.introduction.value = res[0].introduction;
            this.formData.keyPoints.value = keyPoints;
            this.formData.publishDate.value = moment(res[0].publishDate).format('MM/DD/YYYY');
            this.formData.researchPaperUrl = res[0].researchPaperUrl.split('/').pop();
            this.formData.type.value = res[0].type;

            this.formDataCopy = cloneDeep(this.formData);
          } else {
            this.handelApiError();
          }
        })
        .finally(() => {
          this.enableLoader = false;
        })
    },
    validateForm() {
      var isValid = true;
      if (this.$el.querySelector("#title").value === "") {
        this.formData.title.error = true;
        isValid = false;
      } else {
        this.formData.title.error = false;
      }
      if (this.$el.querySelector("#description").value === "") {
        this.formData.description.error = true;
        isValid = false;
      } else {
        this.formData.description.error = false;
      }
      if (this.$el.querySelector("#introduction").value === "") {
        this.formData.introduction.error = true;
        isValid = false;
      } else {
        this.formData.introduction.error = false;
      }
      if (this.$el.querySelector("#keyPoints").value === "") {
        this.formData.keyPoints.error = true;
        isValid = false;
      } else {
        this.formData.keyPoints.error = false;
      }
      if (this.formData.publishDate.value === "") {
        this.formData.publishDate.error = true;
        isValid = false;
      } else {
        this.formData.publishDate.error = false;
      }
      if (this.$el.querySelector("#type").value === "") {
        this.formData.type.error = true;
        isValid = false;
      } else {
        this.formData.type.error = false;
      }
      if (this.formData.title.value !== this.formDataCopy.title.value && this.file == 0) {
        this.formData.researchFile.error = true;
        isValid = false;
      } else {
        this.formData.researchFile.error = false;
      }
      return isValid;
    },
    handleFileSelection: function (event) {
      if (event?.target?.files.length > 0) {
        this.file = event?.target?.files[0]
        this.isFileChanged = true; 
      } else {
        this.file = 0
        this.isFileChanged = false;
      }
    },
    handleTabSwitch(value) {
      this.$emit("tab-switch", this.formId, !value);
    },
    onPublish(event) {
      this.researchDialog = this.dialogConfig?.publishResearch;
      this.showResearchDialog = true;
      event.preventDefault();
    },
    onSubmit(event) {
      if (this.validateForm()) {
        this.researchDialog = this.dialogConfig?.submitResearch;
        this.showResearchDialog = true;
      }
      event.preventDefault();
    },
    onCancel(event) {
      this.researchDialog = this.dialogConfig?.revertResearch;
      this.showResearchDialog = true;
      event.preventDefault();
    },
    handleDialogCancel() {
      this.showResearchDialog = false;
    },
    handleDialogSuccess() {
      switch (this.researchDialog?.action) {
        case 'submit':
          this.submitResearch();
          break;
        case 'cancel':
          this.revertResearch();
          break;
        case 'publish':
          this.publishResearch();
          break;
        default:
          break;
      }
    },
    handleDialogDismiss() {
      this.showResearchDialog = false;
    },
    async submitResearch(){
      this.showResearchDialog = false;
      this.enableLoader = true;
      this.oldResearchId = this.formData.id;
      this.newResearchId = this.formData.title.value !== this.formDataCopy.title.value ? this.getResearchId() : this.formData.id;
      this.fileName = `${this.newResearchId}.pdf`
      let postData = new FormData();
      postData.append("newResearchId", this.newResearchId);
      if (this.isFormChanged) {
        const publishDateFormat = moment(this.formData.publishDate.value).format('YYYY-MM-DD');

        let researchData = {};
        researchData["id"] = this.newResearchId;
        researchData[this.formData.title.name] = this.formData.title.value;
        researchData[this.formData.description.name] = this.formData.description.value;
        researchData["status"] = this.formData.status;
        researchData[this.formData.introduction.name] = this.formData.introduction.value;
        researchData[this.formData.keyPoints.name] = this.formData.keyPoints.value?.split('\n');
        researchData["researchPaperUrl"] = `research-papers/${this.newResearchId}.pdf`;
        researchData[this.formData.publishDate.name] = publishDateFormat;
        researchData[this.formData.type.name] = this.formData.type.value;

        postData.append("researchData", JSON.stringify(researchData));
        postData.append("oldResearchId", this.oldResearchId);
      }
      if (this.isFileChanged && this.file !== 0) {
        postData.append("file", this.file, this.fileName);
      }

      this.setPublishTarget();

      await editResearchData(postData)
        .then(async (resp) => {
          if (resp.status === 201) {
            this.handleSuccessAlert(this.researchDialog.successMessage);
            this.formDataCopy = cloneDeep(this.formData);
            this.isFileChanged = false;
            this.isFormSubmitted = true;
            window.open(`${process.env.VUE_APP_BASE_URL_NONP}`, "_blank");
          } else {
            this.handelApiError()
          }
        })
        .finally(() => {
          this.enableLoader = false;
        });
    },
    async publishResearch() {
      this.showResearchDialog = false;
      this.enableLoader = true;
      let postData = {
        id: this.newResearchId,
        oldResearchId: this.oldResearchId,
        publishType: 'Update',
        publishContent: this.publishTarget
      };
      await publishResearchData(postData)
        .then(async (resp) => {
          if (resp.status === 201) {
            this.handleSuccessAlert(this.researchDialog.successMessage);
            window.open(window.location.protocol + "//" +window.location.host, "_blank");
            this.sendEmailAlert();
            this.resetForm();
          } else {
            this.handelApiError()
          }
        })
        .finally(() => {
          this.enableLoader = false;
        })
    },
    async revertResearch() {
      this.showResearchDialog = false;
      this.enableLoader = true;
      let postData = {};
      await revertResearch(postData)
        .then(async (resp) => {
          if (resp.status === 201) {
            this.handleSuccessAlert(this.researchDialog.successMessage);
            window.open(`${process.env.VUE_APP_BASE_URL_NONP}`, "_blank");
            this.resetForm();
          } else {
            this.handelApiError()
          }
        })
        .finally(() => {
          this.enableLoader = false;
        })
    },
    handleSuccessAlert(alertMsg) {
      setTimeout(() => {
        this.alertData = {
          variation: 'success',
          title: alertMsg,
          delay: 5000,
        };
        this.showAlert = true;
      }, 100);
    },
    handelApiError() {
      this.alertData = {
        variation: "error",
        title: "Error",
        message: "It looks like something went wrong",
      };
      this.showAlert = true;
    },
    async sendEmailAlert(){
      const emailBody = {
        "Research Article Name": this.formData.title.value,
        "Updated Date": moment().format("MMM DD, YYYY"),
        "Updated By": this.userName
      }
      const emailPostData = {
        subject: this.emailConfig.articleEditSubject,
        toEmail: `${process.env.VUE_APP_RESEARCH_PUB_TO_EMAIL}`,
        fromEmail: `${process.env.VUE_APP_RESEARCH_PUB_FROM_EMAIL}`,
        emailContent: emailBody,
      }
      sendMail(emailPostData);
    },
    resetForm() {
      this.formData = cloneDeep(researchFormConfig?.formConfig);
      this.$el.querySelector("#researchFile").value = '';
      this.file = 0;
      this.isFileChanged = false;
      this.isFormSubmitted = false;
      this.getRecentResearch();
    },
    onAlertDismiss() {
      this.showAlert = false;
    },
    getResearchId(){
      const category = ["global-credit-insights", "sasb-spotlight"];
      const titleFormatted = this.formData.title.value.toLowerCase().replace(/[^\w\s]/g, "").replace(/[\s_]+/g, "-");
      if (category.some((value) => titleFormatted.includes(value))) {
        return titleFormatted;
      }
      return `morningstar-credit-${titleFormatted}`;
    },
    setPublishTarget() {
      if (this.isFormChanged && this.isFileChanged) {
          this.publishTarget = 'All';
      } else if (this.isFormChanged && !this.isFileChanged) {
          this.publishTarget = 'Data';
      } else if (!this.isFormChanged && this.isFileChanged) {
          this.publishTarget = 'File';
      } else {
          this.publishTarget = null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.research-form {
  &-action-btn {
    padding: $mds-space-1-x;
  }
}
</style>
