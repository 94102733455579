<template>
  <div>
    <lock-screen-loader v-if="enableLoader"/>
    <app-alert
      v-if="showAlert"
      :alertData="alertData"
      @dismissed="onAlertDismiss"
    />
    <research-dialog
      :dialogConfig="researchDialog"
      :showDialog="showResearchDialog"
      @dialog-success="handleDialogSuccess"
      @dialog-cancel="handleDialogCancel"
      @dialog-dismissed="handleDialogDismiss"
    ></research-dialog>
    <mds-alert
      size="small"
      persistent= persistent
      variation="informational"
      title="Instructions"
      ><div v-html="tabConfig.instructionsText" />
    </mds-alert>
    <research-form :formData="formData" :formId="formId"></research-form>

    <mds-button-container right-aligned class="research-form-action-btn">
      <mds-button v-if="deleteDisabled" variation="secondary" @click="onCancel"> Cancel </mds-button>
      <mds-button variation="primary" :disabled="deleteDisabled" @click="onDelete">Delete</mds-button>
      <mds-button variation="primary" :disabled="publishDisabled"  @click="onPublish">Delete From Prod</mds-button>
    </mds-button-container>
  </div>
</template>

<script>
import ResearchForm from './ResearchForm.vue';
import ResearchDialog from './ResearchDialog.vue';
import { MdsButton, MdsButtonContainer } from "@mds/button";
import cloneDeep from 'lodash/cloneDeep';
import researchFormConfig from '@/data/publishResearch.json';
import LockScreenLoader from '@/components/Common/LockScreenLoader/LockScreenLoader.vue';
import AppAlert from '@/components/Common/AppAlert/AppAlert';
import {
  getResearchList,
  deleteResearchData,
  publishResearchData,
  revertResearch
} from "@/services/researchService.js";
import moment from "moment";
import { sendMail } from "@/services/emailService.js"
import { mapGetters } from 'vuex';
import MdsAlert from '@mds/alert';


export default {
  name: "DeleteResearch",
  components: {
    MdsButton,
    MdsButtonContainer,
    LockScreenLoader,
    AppAlert,
    ResearchForm,
    MdsAlert,
    ResearchDialog
  },
  data() {
    return {
      formData: cloneDeep(researchFormConfig?.formConfig),
      deleteDisabled: false,
      publishDisabled: true,
      enableLoader: false,
      showAlert: false,
      alertData: {},
      dialogConfig: cloneDeep(researchFormConfig?.dialogConfig),
      researchDialog: {},
      showResearchDialog: false,
      emailConfig: cloneDeep(researchFormConfig?.emailConfig),
      persistent: true,
      formId: "delete-research",
      tabConfig: cloneDeep(researchFormConfig?.tabsConfig.tabs[2]),
    };
  },
  computed: {
    ...mapGetters({
      userName: 'user/userName',
    })
  },
  watch: {
    deleteDisabled: 'handleTabSwitch'
  },
  created() {
    this.getRecentResearch();
  },
  methods: {
    async getRecentResearch() {
      this.enableLoader = true;
      await getResearchList()
        .then((res) => {
          if (res) {
            this.enableLoader = false;
            const keyPointsArray = res[0].keyPoints;
            this.formData.id = res[0].id;
            this.formData.title.value = res[0].title;
            this.formData.description.value = res[0].description;
            this.formData.status = res[0].status;
            this.formData.introduction.value = res[0].introduction;
            this.formData.keyPoints.value = keyPointsArray.length > 1 ? keyPointsArray.join("\n") : keyPointsArray[0];
            this.formData.publishDate.value = moment(res[0].publishDate).format('MM/DD/YYYY');
            this.formData.researchPaperUrl = res[0].researchPaperUrl.split('/').pop();
            this.formData.type.value = res[0].type;
          } else {
            this.handelApiError();
          }
        })
        .finally(() => {
          this.enableLoader = false;
        })
    },
    onPublish(event) {
      this.researchDialog = this.dialogConfig?.publishDeleteResearch;
      this.showResearchDialog = true;
      event.preventDefault();
    },
    onDelete(event){
      this.researchDialog = this.dialogConfig?.deleteResearch;
      this.showResearchDialog = true;
      event.preventDefault();
    },
    onCancel(event) {
      this.researchDialog = this.dialogConfig?.revertResearch;
      this.showResearchDialog = true;
      event.preventDefault();
    },
    handleTabSwitch(value) {
      this.$emit("tab-switch", this.formId, !value);
    },
    handleDialogCancel() {
      this.showResearchDialog = false;
    },
    handleDialogDismiss() {
      this.showResearchDialog = false;
    },
    handleDialogSuccess() {
      switch (this.researchDialog?.action) {
        case 'delete':
          this.deleteResearch();
          break;
        case 'cancel':
          this.revertResearch();
          break;
        case 'publish':
          this.publishResearch();
          break;
        default:
          break;
      }
    },
    async deleteResearch(){
      this.showResearchDialog = false;
      this.enableLoader = true;
      const postData = {
        id: this.formData.id,
        fileName: this.formData.researchPaperUrl.split("/").pop()
      };
      await deleteResearchData(postData)
        .then(async (resp) => {
          if (resp.status === 200){
            this.handleSuccessAlert(this.researchDialog.successMessage);
            this.deleteDisabled = true;
            this.publishDisabled = false;
            window.open(`${process.env.VUE_APP_BASE_URL_NONP}`, "_blank");
          } else {
            this.handelApiError();
          }
        })
        .finally(() => {
          this.enableLoader = false;
        });
    },
    async revertResearch() {
      this.showResearchDialog = false;
      this.enableLoader = true;
      let postData = {};
      await revertResearch(postData)
        .then(async (resp) => {
          if (resp.status === 201) {
            this.handleSuccessAlert(this.researchDialog.successMessage);
            this.deleteDisabled = false;
            this.publishDisabled = true;
            window.open(`${process.env.VUE_APP_BASE_URL_NONP}`, "_blank");
            this.getRecentResearch();
          } else {
            this.handelApiError()
          }
        })
        .finally(() => {
          this.enableLoader = false;
        })
    },
    async publishResearch() {
      this.showResearchDialog = false;
      this.enableLoader = true;
      let postData = {
        id: this.formData.id,
        oldResearchId: this.formData.id,
        publishType: 'Delete',
        publishContent: 'All'
      }
      await publishResearchData(postData)
        .then(async (resp) => {
          if (resp.status === 201){
            this.handleSuccessAlert(this.researchDialog.successMessage);
            this.deleteDisabled = false;
            this.publishDisabled = true;
            window.open(window.location.protocol + "//" +window.location.host, "_blank");
            this.sendEmailAlert();
            this.getRecentResearch();
          } else {
            this.handelApiError();
          }
        })
        .finally(() => {
          this.enableLoader = false;
        });
    },
    handleSuccessAlert(alertMsg) {
      setTimeout(() => {
        this.alertData = {
          variation: 'success',
          title: alertMsg,
          delay: 5000,
        };
        this.showAlert = true;
      }, 100);
    },
    onAlertDismiss() {
      this.showAlert = false;
    },
    async sendEmailAlert(){
      const emailBody = {
        "Research Article Name": this.formData.title.value,
        "Deleted Date": moment().format("MMM DD, YYYY"),
        "Deleted By": this.userName
      }
      const emailPostData = {
        subject: this.emailConfig.articleDelSubject,
        toEmail: `${process.env.VUE_APP_RESEARCH_PUB_TO_EMAIL}`,
        fromEmail: `${process.env.VUE_APP_RESEARCH_PUB_FROM_EMAIL}`,
        emailContent: emailBody,
      }
      sendMail(emailPostData);
    },
    handelApiError() {
      this.alertData = {
        variation: "error",
        title: "Error",
        message: "It looks like something went wrong",
      };
      this.showAlert = true;
    }
  }
};
</script>

<style scoped lang="scss">
.research-form {
  &-action-btn {
    padding: $mds-space-1-x;
  }
}
</style>